 const textAbout = {
     classicArt: 'Классическая прорисовка фото с заменой фона\n' +
         '\n' +
         'В стоимость портрета в стиле"CLASSIC" входит:\n' +
         '- Отрисовка макета из вашего фото\n' +
         '- Печать на хлопковом Холсте\n' +
         '- Галлерейная натяжка на деревянный подрамник\n' +
         '- Упаковка для транспортировки',
     classicArtPro: 'Особая стилизация, не похожая на другие! Резкие, ярко выраженные мазки кисти, точная детализация и прорисовка.\n' +
         '\n' +
         'В стоимость портрета входит:\n' +
         '- Отрисовка макета по вашему фото\n' +
         '- Печать на хлопковом холсте\n' +
         '- Галлерейная натяжка на деревянный подрамник\n' +
         '- Упаковка для транспортировки',
     OilPro: 'Особая премиальная стилизация «Oil Pro»\n' +
         '\n' +
         'Это рисунок с нуля! В живую эти работы выглядят так, буд-то их действительно написали маслом. Особый шарм придает картине покрытие текстурным гелем.',
     fotoPrint: 'Количество людей не влияет на стоимость.\n' +
         '\n' +
         'В стоимость портрета входит:\n' +
         '- Подготовка фото\n' +
         '- Печать на хлопковом Холсте\n' +
         '- Галлерейная натяжка на деревянный подрамник\n' +
         '- Упаковка для транспортировки',
     OilLigth: 'Данное направление похоже с «Oil PRO», но есть у них некоторые различия. «Light» - это легкая прорисовка под маслянную живопись, а «PRO» - это рисунок с нуля, с детальной прорисовкой.\n' +
         '\n' +
         'Точную сумму назвать невозможно, так как она зависит от многих факторов. Все просчитываются индивидуально!',
     realisticArt: 'В стоимость портрета в стиле"REALISTIC" входит:\n' +
         '\n' +
         '- Отрисовка макета из вашего фото\n' +
         '- Печать на хлопковом Холсте\n' +
         '- Галлерейная натяжка на деревянный подрамник\n' +
         '- Упаковка для транспортировки',
     artClassicPro: 'Особая стилизация, не похожая на другие! Резкие, ярко выраженные мазки кисти, точная детализация и прорисовка.\n' +
         '\n' +
         'В стоимость портрета входит:\n' +
         '- Отрисовка макета по вашему фото\n' +
         '- Печать на хлопковом холсте\n' +
         '- Галлерейная натяжка на деревянный подрамник\n' +
         '- Упаковка для транспортировки'


 }
 export default textAbout