import React from 'react';
import styles from "./Faq.module.scss"

function Faq() {


    return (
        <section className={styles.section}>
            <div className={styles.container}>
                <h2 className={styles.faq__title}>Вопросы и ответы</h2>
                <details open>
                    <summary>Как можно связаться?</summary>
                    <div className="faq__content">
                        <p className={styles.faq__text}>Вы можете связаться любым удобным для вас способом:
                        </p>
                        <ul>
                            <li> воспользоваться формой заказа на нашем сайте,</li>
                            <li> позвонить по телефону +7 (988) 137-31-97,</li>
                            <li> написать в WhatsApp +7 (914) 564-72-54,</li>
                            <li> прислать на почту art_memory@list.ru</li>
                        </ul>
                    </div>
                </details>
                <details>
                    <summary>Какие требования к изображению?</summary>
                    <div className="faq__content">
                        <p className={styles.faq__text}>Рекомендуемое требование к изображению для печати на холсте —
                            минимальное разрешение 150 dpi (точек на дюйм) для размера холста, который вы хотите
                            напечатать. Кроме того, лучше всего предоставить высококачественное изображение в формате
                            файла, таком как JPEG, PNG или TIFF.

                            Если есть сомнения, то присылайте нам и мы проконсультируем вас.</p>
                    </div>
                </details>
                <details>
                    <summary>Как изготовливаются картины?</summary>
                    <div className="faq__content">
                        <p className={styles.faq__text}>Для наших картин мы используем высококачественный хлопковый
                            холст с матовым покрытием и пигментную печать, не требующию дополнительного защитного слоя.
                            Подрамник изготовлен из клееной сосны, что дает ему дополнительную прочность.
                            <br/>
                            Собираем работу воедино: холст натягиваем на подрамник, с обратной стороны проклеиваем
                            маскировочной лентой, упаковываем и отправляем вам.</p>
                    </div>
                </details>
                <details>
                    <summary>Сколько нужно время для изготовления и доставки заказа?</summary>
                    <div className={styles.faq__content}>
                        <p className={styles.faq__text}>Срок изготовления картин:

                            в стилизаций “Standart” в среднем 1 — 2 рабочих дня. <br/>
                             в стилизаций “Premium” в среднем 7 — 8 рабочих дней <br/>


                                Но все зависит от объема и загруженности.

                                Картины отправляем курьерской службой СДЭК, ЕМS, либо почтой России, все зависит от
                                региона доставки.

                                Перед отправкой хорошо упаковываем картины: пузырьковая пленка, стрейч, картон, когда
                                нужно
                                - пенопласт. Срок доставки зависит от почтовой компании️, поэтому срок и стоимость
                                уточняйте перед заказом!
                        </p>
                    </div>
                </details>
                <details>
                    <summary>Как оплатить?</summary>
                    <div className="faq__content">
                        <p className={styles.faq__text}>Провести оплату электронным способом на карту банка: Visa,
                            MasterCard и «Мир»</p>
                    </div>
                </details>
            </div>
        </section>
);
}

export default Faq;