import React from 'react';
import styles from './Steps.module.scss'
import steps1 from '../../Images/About/steps1.png'
import steps2 from '../../Images/About/steps2.png'
import steps3 from '../../Images/About/steps3.png'
import steps4 from '../../Images/About/steps4.png'
import steps5 from '../../Images/About/steps5.png'



function Steps(props) {
    return (
        <section className={styles.steps}>
            <div className={styles.steps__container}>
                <img className={styles.steps__img} src={steps1} alt="В кАЖДУЮ картину мы вкладываем частичку себя, нашу душу"/>
                <div className={styles.steps__containerText}>
                    <h3 className={styles.steps__title}>В кАЖДУЮ картину мы вкладываем частичку себя, нашу душу</h3>
                    <p className={styles.steps__text}>Мы уверены, что то, насколько долго проживут самые светлые
                        чувства, зависит от того, насколько мы
                        помним эти моменты. На вашу долгую память мы создаем специально для вас наши картины, на которых
                        «застывают» самые счастливые мгновения вашей жизни – свадьба, рождение ребенка, время,
                        проведенное с близкими. Ваши улыбки, ваши чувства и эмоции – вот что на наших картинах!
                        В каждую картину мы вкладываем частичку себя, нашу душу. Поэтому нам кажется важным рассказать о
                        нас, о нашем пути, о нашем деле всей жизни - мастерской «Art Memory».</p>
                </div>
            </div>
            <div className={`${styles.steps__container} ${styles.steps__container_left}`}>
                <img className={styles.steps__img}  src={steps2} alt="Мы решились на большой и рискованный шаг"/>
                <div className={styles.steps__containerText}>
                    <h3 className={styles.steps__title}>Мы решились на большой и рискованный шаг</h3>
                    <p className={styles.steps__text}>Все началось в 2015 году, когда мы начали создавать наши первые
                        работы. Мы работали по вечерам, онлайн, совмещая наше любимое хобби с основной работой. Через
                        два года, в 2017, мы решились на большой и рискованный для нас шаг – оставить скучную, но
                        стабильную офисную работу и полностью посвятить себя любимому делу – искусству. Нас подтолкнуто
                        то, что в этом году мы узнали, что станем родителями. Мы узнали, что наша жизнь изменится
                        совершенно, и изменится к лучшему. И мир мы тоже захотели поменять к лучшему, к добру и свету –
                        с помощью искусства. И вот, мы счастливые родители сразу двух наших детей – дочки Ульяны и
                        нашего детища - мастерской «Art Memory» со студией в городе Благовещенск.

                        На нашем пути было все: и неудачи, и падения. Но гораздо больше было радости от свершений и
                        осознания того, что вот именно сейчас мы собственными руками воплощаем нашу мечту в жизнь,
                        творим наше настоящее и наше будущее.</p>
                </div>
            </div>
            <div className={`${styles.steps__container}`}>
                <img className={styles.steps__img} src={steps3} alt="Наша жизнь состоит не из времени, а из моментов"/>
                <div className={styles.steps__containerText}>
                    <h3 className={styles.steps__title}>Наша жизнь состоит не из времени, а из моментов</h3>
                    <p className={styles.steps__text}>Как выросли за шесть лет наши дети! Дочка – уже скоро школьница. А
                        у нашей мастерской - собственное производство в городе Краснодар, работают филиалы в городах
                        Благовещенск, Абакан, Анапа. Вот такая география – с юго-запада России до самого востока!
                        Заканчивается рабочий день в одном филиале, начинается - в другом. Поэтому и работаем для вас мы
                        почти 24 часа в сутки.

                        Мы гордимся тем, что с каждым годом в нашей дружной семье все больше увлеченных, творческих
                        ребят, которые стремятся к развитию и покорению новых вершин. При этом наша цель одна: продлить
                        для вас ощущения счастья. Ведь, по сути, наша жизнь состоит не из времени, а из моментов,
                        которые мы помним.
                        Наша мастерская специализируется на уникальных и эксклюзивных картинах ручной работы. Мы
                        изготавливаем картины премиум-качества по максимально приемлемым ценам.</p>
                </div>
            </div>
            <div className={`${styles.steps__container} ${styles.steps__container_left}`}>
                <img className={styles.steps__img} src={steps4} alt="Наша работа – искусство"/>
                <div className={styles.steps__containerText}>
                    <h3 className={styles.steps__title}>Наша работа – искусство</h3>
                    <p className={styles.steps__text}>Для нас наша работа – не просто источник заработка, а наше любимое
                        дело. Поэтому каждая наша картина создается с максимальным вниманием к деталям и максимальной
                        заботой. Мы всегда стараемся совершенствовать наш продукт и доводить его до идеала. Так, чтобы
                        наши клиенты оставались довольны каждым заказом, каждым изделием из нашей мастерской.

                        Наша работа – это искусство. Мы не создаем наши картины «под копирку» — это неинтересно и не
                        отражает ценности мастерской «Art Memory». Мы создаем единственные, уникальные в своем роде
                        картины, на которых отражены самые важные и самые запоминающиеся моменты жизни людей. Так, чтобы
                        каждое созданное нами произведение было чем-то большим, чем просто разукрашенный холст, тем, чем
                        вы будете дорожить и гордиться долгие годы!</p>
                </div>
            </div>
            <div className={`${styles.steps__container}`}>
                <img className={styles.steps__img} src={steps5} alt="Картины отражающие эммоции и чувства"/>
                <div className={styles.steps__containerText}>
                    <h3 className={styles.steps__title}>Картины отражающие эммоции и чувства</h3>
                    <p className={styles.steps__text}>Мы делаем все, чтобы заказанные у нас картины полностью отражали
                        именно вас, ваши эмоции и ваши чувства. Для этого мы не просто просим, а настаиваем на том,
                        чтобы мы, исполнители, и вы, заказчики, узнали друг о друге больше! Поэтому мы будем рады
                        общению с вами. Мы готовы ответить на все ваши вопросы, рассказать вам о процессе создания
                        настоящих шедевров. Будьте уверены, что картины, заказанные в нашей мастерской, будут
                        по-настоящему уникальными и особенными.</p>
                </div>
            </div>
            <p className={styles.steps__centerText}>Мы благодарим вас за то, что вы нашли время, чтобы узнать об «Art Memory» больше. С нетерпением ждем сотрудничества с Вами!</p>
        </section>

)
    ;
}

export default Steps;