import React from 'react';
import styles from "./Faq.module.scss"

function Faq2() {


    return (
        <section className={styles.section}>
            <div className={styles.container}>
                <h2 className={styles.faq__title}>С чего начать</h2>
                <details open>
                    <summary>С чего начать делать картины?</summary>
                    <div className="faq__content">
                        <p className={styles.faq__text}>
                            Если ты Digital художник, фотограф или может просто хочешь занять эту нишу, то читай до
                            конца
                        </p>
                        <p className={styles.faq__text}>Первое, и самое основное - это найти надежного партнера
                            (Производство) Там, где качество и честность, там успех!</p>
                        <p className={styles.faq__text}>Второе - это сделать себе несколько примеров работ, чтобы в
                            живую убедится в качестве и отснять живой контент. Именно живой!!!</p>
                        <p className={styles.faq__text}>Можно конечно спи..ть фото, видео у других студий, как делает
                            большинство, но не рекомендую начинать свой путь с обмана;) Карма настигнет! Либо суд...</p>
                        <p className={styles.faq__text}>Ну или можно попасть на круглую сумму с шестью нулями, если
                            обратятся в суд.</p>
                        <p className={styles.faq__text}>Третье - это работать каждый день, прям каждый божий день!!!
                            Придумывать что-то своё и набирать аудиторию. Зарабатывать себе имя, а не тупо сливать
                            деньги в таргет.
                        </p>
                        <p className={styles.faq__text}>Ведь самый простой и бесплатный метод рекламы - «САРАФАН»,
                            проверено лично!</p>
                        <p className={styles.faq__text}>Конечно, в конце моего сообщения просится фраза, «покупай мой
                            курс, по ссылке в шапке профиля», но не в этот раз...</p>

                    </div>
                </details>
                <details>
                    <summary>Воровство</summary>
                    <div className="faq__content">
                        <p className={styles.faq__text}>Воровство - это самый позорный вариант в бизнесе‼️ В Китае за такие деяния отрубали руки, но, к сожалению, в России и странах СНГ это НОРМА...</p>
                        <p className={styles.faq__text}>Для меня стрёмно взять у кого-то контент и выдать его за свой. Всегда можно спросить или купить...</p>
                        <p className={styles.faq__text}>В контент люди вкладывают свои деньги, силы, время, а какой-то Вася взял и просто выдал за своё</p>
                        <p className={styles.faq__text}>90% профилей в инсте используют не свои работы. Берут чужие фото, тексты, мысли. Накручивают отзывы, подписчиков, комментарии и т.д.</p>
                        <p className={styles.faq__text}>Я против всего этого и стараюсь делать что-то своё, чтобы выгодно отличатся от всех остальных!
                        </p>
                        <p className={styles.faq__text}>Но это мое мнение! Могу быть старомодным</p>
                        <p className={styles.faq__text}>Начинать свой путь с обмана - это плохо!!! Клиент чувствует, когда от него что-то скрывают.</p>
                        <p className={styles.faq__text}>И главный момент, как ты сможешь отдать хороший продукт, если ты его даже сделать не можешь</p>
                        <p className={styles.faq__text}>Картинка то у тебя красивая, а вот в жизни работа будет не «ВАУ».</p>
                        <p className={styles.faq__text}>Задумайся‼️</p>
                        <p className={styles.faq__text}>МОРАЛЬ..</p>
                        <p className={styles.faq__text}>Работайте честно, сами! Придумывайте, создавайте, творите. Ведь это и придаёт вам индивидуальность ;)</p>
                    </div>
                </details>
                <details>
                    <summary>Как производятся картины?</summary>
                    <div className="faq__content">
                        <p className={styles.faq__text}>Изготавливаю работы я прям дома - это большой плюс в плане
                            скорости. Оперативно, качественно, всё на совесть. Почти как себе (для себя если честно
                            меньше заморачиваюсь)</p>
                        <p className={styles.faq__text}>Печать на хлопковом холсте (не китайская хрень), а настоящий
                            хлопковый холст с матовым покрытием.</p>
                        <p className={styles.faq__text}>Очень приятный на ощупь, легко поддаётся дорисовке по верх
                            холста и покрытию текстурным гелем (бывает даже рисую на испорченных работах, очень хорошо
                            держит краску).</p>
                        <p className={styles.faq__text}>Теперь о главном - это сама печать.</p>
                        <p className={styles.faq__text}>Это ТОПза 5 лет работы не видел лучше, зуб даю)</p>
                        <p className={styles.faq__text}>Пигментная печать на максималках (не путать с латексной или не
                            дай бог экосальвент.</p>
                        <p className={styles.faq__text}>Это совершенно другой уровень, картинка выходит что на экране,
                            что в живую. Чернила использую только оригинал, не совместимые! Наши работы не нужно
                            покрывать лаком, чтоб типо для большего срока хранения и насыщoения цвета. Там и так все
                            огонь.</p>
                        <p className={styles.faq__text}>Подрамник - клеёная сосна. Пилю, склеиваю и натягиваю - всё
                            сам! Подрамник, более чем крепкий, мне кажется, если скинуть его с 10 этажа, то он
                            выдержит, но это не точно.</p>
                        <p className={styles.faq__text}>Ну и собираем работу воедино: холст натягиваем на подрамник, с
                            обратной стороны проклеиваем маскировочной лентой, упаковываем и отправляем вам, либо
                            напрямую заказчику.</p>
                    </div>
                </details>
                <details>
                    <summary>Какие гарантии?</summary>
                    <div className={styles.faq__content}>
                        <p className={styles.faq__text}>Какие гарантии? - часто задают нам этот вопрос.</p>
                        <p className={styles.faq__text}>Если честно, порой даже и не знаем как на него ответить, но
                            скажем так, мы в первую очередь люди, которые дорожат своей репутацией и своим именем.</p>
                        <p className={styles.faq__text}>За все время нашей работы не было случаев, чтобы мы кого-то
                            кинули или обманули.</p>
                        <p className={styles.faq__text}>Мы всегда открыты перед нашими заказчиками. В сторис нашего
                            основного профиля @artmemory_rus показываем свою жизнь, себя, семью, наш дом. Многие знают
                            где мы живём)</p>
                        <p className={styles.faq__text}>Именно это для нас и является основной гарантией</p>
                        <p className={styles.faq__text}>Ну а если смотреть со стороны корыстных побуждений) чем честнее
                            мы будем с вами, тем больше заказов будет у нас!</p>

                    </div>
                </details>
                <details>
                    <summary>Сроки изготовления и доставка</summary>
                    <div className="faq__content">
                        <p className={styles.faq__text}><p className={styles.faq__text}>Срок изготовления в среднем 1-2
                            дня. Все зависит от объема и загруженности.</p>
                            <p className={styles.faq__text}>К примеру: 5 работ, 50/70см, я сделаю быстрее, чем одну -
                                50/40см. Поэтому, скидывать лучше несколько работ сразу, либо уточнять сроки по
                                факту.</p>
                            <p className={styles.faq__text}>Максимальный срок изготовления работы - 3 дня!</p>
                            <p className={styles.faq__text}>Теперь доставка:</p>
                            <p className={styles.faq__text}>Отправляю работы курьерской службой СДЭК, либо почтой
                                России, все зависит от региона доставки. Какой вариант быстрее и приемлемей цена, тем и
                                отправим.</p>
                            <p className={styles.faq__text}>В обоих случаях упаковываем картины очень хорошо:
                                пузырьковая пленка, стрейч, картон, когда нужно - пенопласт.</p>
                            <p className={styles.faq__text}>Срок доставки зависит не от меня. Поэтому могу сказать
                                только приблизительные данные. Срок и стоимость уточняйте перед заказом!</p>
                        </p>
                    </div>
                </details>
                <details>
                    <summary>Почему Я?</summary>
                    <div className="faq__content">
                        <p className={styles.faq__text}>Здесь я должен написать какой я хороший, а другие плохие и т.д.,
                            но я так делать не буду.</p>
                        <p className={styles.faq__text}>Каждый работает по совести! Основываясь на своём опыте и
                            обратной связи от партнеров могу сказать, что качество моего продукта очень отличается от
                            многих печатников.</p>
                        <p className={styles.faq__text}>- Почему?</p>
                        <p className={styles.faq__text}>- Да всё просто!</p>
                        <p className={styles.faq__text}>Точно такой же продукт я делаю для своих студий ART MEMORY.</p>
                        <p className={styles.faq__text}>Для меня нет разницы: друг, партнёр, родственник... Я всем делаю
                            ОДИНАКОВО КРУТО!</p>
                        <p className={styles.faq__text}>Со всеми я стараюсь выстраивать человеческие отношения, а не
                            тупо денежные! Мне не выгодно делать плохо, поэтому я научился делать хорошо, теперь могу
                            помочь и тебе)</p>
                        <p className={styles.faq__text}>Если ты так же топишь за качество, а не только выгоду в три
                            копейки, тогда нам по пути ;) А если тебе пофиг на уровень и качество работ, тогда делай как
                            делаешь.</p>
                    </div>
                </details>
            </div>
        </section>
    );
}

export default Faq2;